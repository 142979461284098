<script setup>
import { onMounted, ref, watch, reactive, onBeforeMount } from "vue";
import { useUserStore } from "../../stores/user";
import { useRoute, useRouter } from "vue-router/auto";
import pageTitle, { setPageTitle } from "../../utils/pageTitle";
import c3api from "@/c3api";
// import InboundOrders from "../../components/InboundOrders.vue";

// const { updatePage, inboundOrders, fetchInboundOrders } = useInboundOrders();
const userStore = useUserStore();
const route = useRoute();
// const router = useRouter();
const client = ref("");

onBeforeMount(async () => {
  await c3api.get(`/clients/${route.query.client_id}`).then((clientResponse) => {
    if (clientResponse.data.data) {
      client.value = clientResponse.data.data;
    }
  });
});

onMounted(() => {
  setPageTitle("New Inbound Shipment");
});
</script>

<template>
  <v-card-title>{{ pageTitle }}</v-card-title>
  <v-card-subtitle>Add new Inbound Order Shipment. </v-card-subtitle>
  <div id="orders-grid-wrapper">
    <!--    <InboundOrders />-->
  </div>
</template>

<style>
#orders-grid-wrapper {
  height: 100%;
  padding-bottom: 110px;
}
</style>
